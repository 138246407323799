@import url(https://fonts.googleapis.com/css?family=Roboto);
/** Animation stuffs */
body {
  margin: 0;
  padding: 0;
  background: #f5f5f7;
  font-family: 'Roboto', sans-serif;
  color: #333; }

.app_main_wrapper {
  position: relative;
  width: 100%;
  height: 100%; }

.global_pop_wrapper {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #fff; }

a {
  color: #31579d;
  text-decoration: none; }

.nonactiveHeaderLink {
  color: #fff; }

.activeHeaderLink {
  color: #a9d800; }

input[type="checkbox"] {
  width: 10px;
  height: 10px; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; }

.avatar_menu_img {
  border-radius: 50%;
  border: 1px solid #ccc; }

.avatar_menu_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  justify-content: center;
  line-height: 40px; }

button:focus {
  outline: 0; }

.bg_black_transp {
  position: fixed;
  z-index: 6;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 60px;
  background: rgba(0, 0, 0, 0.6); }

.logo_top_left {
  position: fixed;
  z-index: 3;
  top: 10px;
  left: 20px; }

.logo_top_left img {
  width: 170px; }

.sign_transp_wrapper {
  position: fixed;
  z-index: 10;
  top: 80px;
  right: 50%;
  margin-right: -725px;
  width: 450px;
  height: 510px;
  overflow: hidden;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.8); }

.signup_transp_wrapper {
  position: fixed;
  z-index: 10;
  top: 80px;
  right: 50%;
  margin-right: -725px;
  width: 450px;
  height: 550px;
  overflow: hidden;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.8); }

.resetpswd_transp_wrapper {
  position: fixed;
  z-index: 10;
  top: 80px;
  right: 50%;
  margin-right: -725px;
  width: 450px;
  height: 440px;
  overflow: hidden;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.8); }

.sign_transp_inner {
  padding: 40px;
  padding-top: 30px; }

.sign_title {
  font-size: 32px;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
  width: 370px; }

/* Text Input */
.basic_ti {
  height: 40px;
  padding: 6px;
  background: #fff;
  width: 358px;
  border: 1px solid #111;
  border-radius: 4px; }

.basic_ti_top {
  height: 40px;
  padding: 6px;
  background: #fff;
  width: 358px;
  border: 1px solid #111;
  border-radius: 4px;
  border-bottom: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; }

.basic_ti_middle {
  height: 40px;
  padding: 6px;
  background: #fff;
  width: 358px;
  border: 1px solid #111;
  border-top: 1px solid #bbb;
  border-bottom: none;
  border-radius: none;
  -moz-border-radius-topleft: 0px;
  border-radius: 0px;
  border-radius: 0px; }

.basic_ti_bottom {
  height: 40px;
  padding: 6px;
  background: #fff;
  width: 358px;
  border-radius: 4px;
  border: 1px solid #111;
  border-top: 1px solid #bbb;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px; }

/* BUTTON */
.buttonBlackText {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  width: 200px;
  height: 40px;
  justify-content: center;
  line-height: 40px;
  border-radius: 4px;
  cursor: pointer; }

.buttonWhiteText {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  width: 200px;
  height: 40px;
  justify-content: center;
  line-height: 40px;
  border-radius: 4px;
  cursor: pointer; }

/* HEADER */
.main_header {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: #006229; }

.main_header_mobile {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: #006229; }

.main_header_connected {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: rgba(255, 255, 255, 0.8);
  overflow: hidden; }

.main_header_inside {
  width: 98%;
  height: 50px;
  margin: auto;
  margin-top: 5px;
  overflow: hidden; }

.main_header_inside_mobile {
  width: 98%;
  height: 50px;
  margin: auto;
  margin-top: 5px;
  overflow: hidden; }

.main_header_inside_left {
  width: 170px;
  height: 50px;
  justify-content: center;
  line-height: 50px;
  float: left; }

.main_header_inside_left_2 {
  height: 50px;
  justify-content: center;
  line-height: 50px;
  float: left;
  margin-left: 30px; }

.main_header_inside_left_2 a {
  display: inline-block;
  width: 100px;
  text-align: center;
  font-size: 16px; }

.main_header_inside_left img {
  width: 170px;
  vertical-align: middle; }

.main_header_inside_left_mobile {
  width: 30%;
  height: 50px;
  justify-content: center;
  line-height: 50px;
  float: left; }

.main_header_inside_left_mobile img {
  width: 100%;
  vertical-align: middle; }

.main_header_inside_center {
  margin-left: 20px;
  width: 480px;
  height: 44px;
  margin-top: 3px;
  justify-content: center;
  float: left; }

.mainmenu {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  /* remove default list style */ }

.mainmenu li {
  margin: 0;
  padding: 0;
  width: 80px;
  text-align: center;
  height: 50px;
  float: left; }

.mainmenu li a {
  float: left;
  width: 80px;
  text-align: center;
  height: 50px;
  padding: 0;
  margin: 0; }

.navLinkactive {
  color: #5d901e; }

.navLinkLabel {
  color: #777; }

.navLinkactive .navLinkLabel {
  color: #5d901e; }

.liHome .navLinkinactive .navLinkIcon {
  background: url(https://s3.amazonaws.com/debyooinc19.img/nav/feedgrey.png) no-repeat;
  width: 32px;
  height: 32px;
  display: inline-block;
  background-size: 32px 32px; }

.liHome .navLinkactive .navLinkIcon {
  background: url(https://s3.amazonaws.com/debyooinc19.img/nav/feedgreen.png) no-repeat;
  width: 32px;
  height: 32px;
  display: inline-block;
  background-size: 32px 32px; }

.liSearch .navLinkinactive .navLinkIcon {
  background: url(https://s3.amazonaws.com/debyooinc19.img/nav/searchgrey.png) no-repeat;
  width: 32px;
  height: 32px;
  display: inline-block;
  background-size: 32px 32px; }

.liSearch .navLinkactive .navLinkIcon {
  background: url(https://s3.amazonaws.com/debyooinc19.img/nav/searchgreen.png) no-repeat;
  width: 32px;
  height: 32px;
  display: inline-block;
  background-size: 32px 32px; }

.liDChat .navLinkinactive .navLinkIcon {
  background: url(https://s3.amazonaws.com/debyooinc19.img/nav/dchatgrey.png) no-repeat;
  width: 32px;
  height: 32px;
  display: inline-block;
  background-size: 32px 32px; }

.liDChat .navLinkactive .navLinkIcon {
  background: url(https://s3.amazonaws.com/debyooinc19.img/nav/dchatgreen.png) no-repeat;
  width: 32px;
  height: 32px;
  display: inline-block;
  background-size: 32px 32px; }

.liNewStory .navLinkinactive .navLinkIcon {
  background: url(https://s3.amazonaws.com/debyooinc19.img/nav/newstorygrey.png) no-repeat;
  width: 32px;
  height: 32px;
  display: inline-block;
  background-size: 32px 32px; }

.liNewStory .navLinkactive .navLinkIcon {
  background: url(https://s3.amazonaws.com/debyooinc19.img/nav/newstorygreen.png) no-repeat;
  width: 32px;
  height: 32px;
  display: inline-block;
  background-size: 32px 32px; }

.liDShop .navLinkinactive .navLinkIcon {
  background: url(https://s3.amazonaws.com/debyooinc19.img/nav/dshopgrey.png) no-repeat;
  width: 32px;
  height: 32px;
  display: inline-block;
  background-size: 32px 32px; }

.liDShop .navLinkactive .navLinkIcon {
  background: url(https://s3.amazonaws.com/debyooinc19.img/nav/dshopgreen.png) no-repeat;
  width: 32px;
  height: 32px;
  display: inline-block;
  background-size: 32px 32px; }

.liDPlay .navLinkinactive .navLinkIcon {
  background: url(https://s3.amazonaws.com/debyooinc19.img/nav/librarygrey.png) no-repeat;
  width: 32px;
  height: 32px;
  display: inline-block;
  background-size: 32px 32px; }

.liDPlay .navLinkactive .navLinkIcon {
  background: url(https://s3.amazonaws.com/debyooinc19.img/nav/librarygreen.png) no-repeat;
  width: 32px;
  height: 32px;
  display: inline-block;
  background-size: 32px 32px; }

.main_header_inside_center_mobile {
  text-align: center;
  width: 40%;
  height: 50px;
  line-height: 50px;
  float: left; }

.main_header_inside_center_mobile img {
  width: 100%;
  vertical-align: middle; }

.main_header_inside_right {
  position: relative;
  text-align: right;
  width: 350px;
  height: 50px;
  float: right; }

.main_header_inside_right img {
  height: 50px;
  vertical-align: middle; }

.main_header_inside_right_1 {
  position: relative;
  float: right;
  margin-left: 20px;
  margin-top: 5px; }

.main_header_inside_right_2 {
  position: relative;
  float: right;
  margin-top: 5px; }

.main_header_inside_right_mobile {
  position: relative;
  text-align: right;
  width: 30%;
  height: 50px;
  line-height: 50px;
  float: right; }

.main_header_inside_right_mobile img {
  width: 100%;
  vertical-align: middle; }

.menu_container {
  position: fixed;
  top: 19px;
  right: 7px;
  height: 27px;
  width: 35px;
  cursor: pointer;
  z-index: 9999;
  -webkit-transition: opacity .25s ease;
  transition: opacity .25s ease; }
  .menu_container:hover {
    opacity: 1; }
  .menu_container.active .top {
    -webkit-transform: translateY(11px) translateX(0) rotate(45deg);
            transform: translateY(11px) translateX(0) rotate(45deg);
    background: #777; }
  .menu_container.active .middle {
    opacity: 0;
    background: #777; }
  .menu_container.active .bottom {
    -webkit-transform: translateY(-11px) translateX(0) rotate(-45deg);
            transform: translateY(-11px) translateX(0) rotate(-45deg);
    background: #777; }
  .menu_container span {
    background: #fff;
    border: none;
    height: 3px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: all .35s ease;
    transition: all .35s ease;
    cursor: pointer; }
    .menu_container span:nth-of-type(2) {
      top: 11px; }
    .menu_container span:nth-of-type(3) {
      top: 22px; }

.overlay {
  position: fixed;
  z-index: 10;
  background: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .35s, visibility .35s, height .35s;
  transition: opacity .35s, visibility .35s, height .35s;
  overflow: hidden; }
  .overlay.open {
    opacity: 1;
    visibility: visible;
    height: 100%; }
    .overlay.open li {
      -webkit-animation: fadeInRight .5s ease forwards;
              animation: fadeInRight .5s ease forwards;
      -webkit-animation-delay: .35s;
              animation-delay: .35s; }
      .overlay.open li:nth-of-type(2) {
        -webkit-animation-delay: .4s;
                animation-delay: .4s; }
      .overlay.open li:nth-of-type(3) {
        -webkit-animation-delay: .45s;
                animation-delay: .45s; }
      .overlay.open li:nth-of-type(4) {
        -webkit-animation-delay: .50s;
                animation-delay: .50s; }
  .overlay nav {
    position: relative;
    height: 70%;
    top: 35%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 20px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    text-align: center;
    width: 98%;
    margin: auto; }
  .overlay ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;
    width: 100%; }
    .overlay ul li {
      display: block;
      height: 50px;
      min-height: 50px;
      line-height: 50px;
      width: 100%;
      position: relative;
      opacity: 0; }
      .overlay ul li a {
        display: block;
        position: relative;
        color: #333;
        text-decoration: none;
        overflow: hidden;
        vertical-align: middle; }
        .overlay ul li a:hover:after, .overlay ul li a:focus:after, .overlay ul li a:active:after {
          width: 100%; }
        .overlay ul li a:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0%;
          -webkit-transform: translateX(-50%);
                  transform: translateX(-50%);
          height: 3px;
          background: #FFF;
          -webkit-transition: .35s;
          transition: .35s; }

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%; }
  100% {
    opacity: 1;
    left: 0; } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%; }
  100% {
    opacity: 1;
    left: 0; } }

.logo-overlay-menu-mobile {
  margin: 17px;
  margin-left: 10px; }

.logo-overlay-menu-mobile img {
  width: 140px; }

/* END OF HEADER */
/* BODY */
.white-round-container {
  background: #fff;
  width: 1020px;
  border-radius: 6px;
  border: 0.5pt solid #ddd; }

.padding-20 {
  padding: 20px; }

.global-activeuser-changed-icon {
  width: 60px;
  height: 60px;
  line-height: 65px;
  text-align: center;
  background: #8dc82c;
  border-radius: 50%;
  font-size: 45px;
  color: #FFF;
  margin: auto;
  margin-top: 30px; }

.page-title {
  color: #222;
  font-size: 35px;
  font-weight: bold;
  line-height: 40px; }

.clear-both {
  clear: both; }

.h40 {
  height: 40px; }

.float-25pc {
  float: left;
  width: 25%; }

.float-50pc {
  float: left;
  width: 50%; }

.page-subtitle-underlined {
  color: #222;
  font-size: 18px;
  line-height: 30px;
  width: 96%;
  border-bottom: 1px solid #ccc; }

.default-text {
  color: #333;
  font-size: 14px; }

/* Home */
.home-title-container {
  position: absolute;
  z-index: 6;
  top: 80px;
  left: 50%;
  width: 1020px;
  margin-left: -510px;
  text-align: center; }

.home-title-container .title {
  position: relative;
  color: #111;
  font-size: 50px;
  font-weight: bold;
  line-height: 60px;
  text-align: center; }

.home-title-container-mobile {
  position: absolute;
  z-index: 6;
  top: 75px;
  left: 2%;
  width: 96%;
  text-align: center; }

.home-title-container-mobile .title {
  position: relative;
  color: #111;
  font-size: 23px;
  font-weight: bold;
  line-height: 26px;
  text-align: center; }

.home-text-container {
  position: absolute;
  z-index: 6;
  top: 27%;
  right: 50%;
  width: 570px;
  height: auto;
  margin-right: -540px; }

.home-text-container .title {
  position: relative;
  color: #111;
  font-size: 80px;
  font-weight: bold;
  line-height: 90px; }

.home-text-container .sub-title-big {
  margin-top: 10px;
  position: relative;
  color: #111;
  font-size: 55px;
  line-height: 60px; }

.home-text-container .sub-title {
  margin-top: 10px;
  position: relative;
  color: #111;
  font-size: 45px;
  line-height: 55px; }

.home-text-container .body-text {
  position: relative;
  margin-top: 20px;
  color: #222;
  font-size: 40px;
  line-height: 50px; }

/* Contact */
.contact-container {
  position: absolute;
  z-index: 6;
  top: 80px;
  left: 50%;
  width: 1020px;
  margin-left: -510px; }

/* END OF BODY */
/* FOOTER */
.home_footer_right {
  position: absolute;
  z-index: 9;
  bottom: 10px;
  right: 50%;
  margin-right: -725px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  padding-left: 15px;
  padding-right: 15px;
  background: rgba(255, 255, 255, 0.1);
  text-align: center;
  overflow: hidden; }

.home_footer_right a {
  color: #333; }

/* END OF FOOTER */
.slide-container {
  width: 70%;
  margin: auto; }

h3 {
  text-align: center; }

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 300px; }

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center; }

.each-fade {
  display: flex;
  width: 100%;
  height: 100vh; }

.each-fade .image-container {
  position: absolute;
  z-index: 8;
  top: 26%;
  left: 50%;
  width: 450px;
  margin-left: -540px;
  overflow: hidden; }

.each-fade .image-container img {
  width: 450px; }

.each-fade .signin-image-container {
  position: absolute;
  z-index: 8;
  top: 26%;
  left: 50%;
  margin-left: -725px;
  width: 450px;
  overflow: hidden; }

.each-fade .signin-image-container img {
  width: 450px; }

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed; }

.bg_img_wrapper {
  position: absolute;
  z-index: 8;
  top: 0;
  bottom: 0;
  width: 300px;
  height: 600px;
  margin-left: 0px; }

.bg_img_wrapper img {
  width: 300px;
  height: 600px; }

.bg_elegance {
  background-image: radial-gradient(73% 147%, #EADFDF 59%, #ECE2DF 100%), radial-gradient(91% 146%, rgba(255, 255, 255, 0.5) 47%, rgba(0, 0, 0, 0.5) 100%);
  background-blend-mode: screen; }

.bg_chemic_aqua {
  background-color: #CDDCDC;
  background-image: radial-gradient(at 50% 100%, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.25)), to(rgba(0, 0, 0, 0.25)));
  background-image: radial-gradient(at 50% 100%, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), linear-gradient(to bottom, rgba(255, 255, 255, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%);
  background-blend-mode: screen, overlay; }

.bg_glass_water {
  background-image: -webkit-gradient(linear, left bottom, left top, from(#dfe9f3), to(white));
  background-image: linear-gradient(to top, #dfe9f3 0%, white 100%); }

.bg_earl_gray {
  background-color: #E4E4E1;
  background-image: radial-gradient(at top center, rgba(255, 255, 255, 0.03) 0%, rgba(0, 0, 0, 0.03) 100%), -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0.1)), to(rgba(143, 152, 157, 0.6)));
  background-image: radial-gradient(at top center, rgba(255, 255, 255, 0.03) 0%, rgba(0, 0, 0, 0.03) 100%), linear-gradient(to top, rgba(255, 255, 255, 0.1) 0%, rgba(143, 152, 157, 0.6) 100%);
  background-blend-mode: normal, multiply; }

.bg_mole_hall {
  background-image: linear-gradient(-20deg, #616161 0%, #9bc5c3 100%); }

.bg_kind_steel {
  background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%); }

.bg_clean_mirror {
  background-image: linear-gradient(45deg, #93a5cf 0%, #e4efe9 100%); }

.bg_jungle_day {
  background-image: linear-gradient(45deg, #8baaaa 0%, #ae8b9c 100%); }

/* CHECKBOX */
.m-checkbox {
  display: flex;
  align-items: center;
  padding: .5rem; }
  .m-checkbox__label {
    flex-shrink: 0;
    padding: .5rem 1rem;
    color: #fff;
    cursor: pointer; }
  .m-checkbox__input {
    position: relative;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    background: #fff;
    cursor: pointer;
    border: 2px solid #0867ae; }
    .m-checkbox__input::before {
      content: ' ';
      position: absolute;
      top: 50%;
      right: 50%;
      bottom: 50%;
      left: 50%;
      -webkit-transition: all .1s;
      transition: all .1s;
      background: #0867ae; }
    .m-checkbox__input:checked::before, .m-checkbox__input:indeterminate::before {
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px; }
    .m-checkbox__input:indeterminate::before {
      top: 17px;
      bottom: 17px; }
    .m-checkbox__input:disabled {
      border-color: white;
      cursor: default; }
      .m-checkbox__input:disabled::before {
        background-color: white; }
      .m-checkbox__input:disabled + .m-checkbox__label {
        color: gray;
        cursor: default; }
  .m-checkbox--has-error__input {
    border-color: red;
    background-color: rgba(255, 0, 0, 0.2); }
    .m-checkbox--has-error__input::before {
      background-color: red; }
  .m-checkbox--switch__label::after {
    content: 'off';
    margin-left: .25rem; }
  .m-checkbox--switch__input {
    width: 76px;
    height: 40px;
    border: 2px solid white;
    border-radius: 36px; }
    .m-checkbox--switch__input::before {
      top: 2px;
      right: 38px;
      bottom: 2px;
      left: 2px;
      border-radius: 50%;
      background: white; }
    .m-checkbox--switch__input:checked {
      border-color: #0867ae; }
      .m-checkbox--switch__input:checked + .m-checkbox--switch__label::after {
        content: 'on'; }
      .m-checkbox--switch__input:checked::before {
        right: 2px;
        left: 38px;
        background: #0867ae; }

/* ========================================================= */
/* Dropdown menu Source */
/* ========================================================= */
/** Box shadows */
.dd-menu {
  display: inline-block;
  position: fixed;
  z-index: 9;
  top: 10px;
  right: 20px;
  text-align: center; }
  .dd-menu.dd-menu-left {
    text-align: left; }
    .dd-menu.dd-menu-left .dd-menu-items {
      right: -20px;
      border-top: none; }
      .dd-menu.dd-menu-left .dd-menu-items button, .dd-menu.dd-menu-left .dd-menu-items a {
        text-align: left; }
  .dd-menu .dd-menu-items {
    position: absolute;
    z-index: 7;
    margin-top: 0.5em; }
    .dd-menu .dd-menu-items > ul,
    .dd-menu .dd-menu-items > ol {
      width: 250px;
      border: 1px solid #ccc;
      list-style: none;
      padding: 0;
      margin: 0;
      background-color: #ffffff;
      color: #000000;
      box-shadow: compact(0 0 4px rgba(0, 0, 0, 0.15)), 0 2px 4px rgba(0, 0, 0, 0.29); }
      .dd-menu .dd-menu-items > ul > li,
      .dd-menu .dd-menu-items > ol > li {
        height: 40px;
        padding-left: 5px;
        cursor: pointer;
        font-size: 15px; }
      .dd-menu .dd-menu-items > ul > li:hover,
      .dd-menu .dd-menu-items > ul > li > button:focus,
      .dd-menu .dd-menu-items > ul > li > a:focus,
      .dd-menu .dd-menu-items > ol > li:hover,
      .dd-menu .dd-menu-items > ol > li > button:focus,
      .dd-menu .dd-menu-items > ol > li > a:focus {
        color: #000000;
        background-color: #d9e99d;
        outline: none;
        height: 40px; }
  .dd-menu li.separator {
    content: '';
    height: 1px;
    background-color: rgba(0, 0, 0, 0.15);
    margin-top: .5em;
    margin-bottom: .5em; }

.clear-btn, .dd-menu .dd-menu-items > ul > li > button,
.dd-menu .dd-menu-items > ol > li > button {
  border: none;
  background-color: transparent; }

/* ========================================================= */
/* Dropdown menu Animations */
/* ========================================================= */
.grow-from-left-enter {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  transition: -webkit-transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  transition: transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  transition: transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8); }
  .grow-from-left-enter.grow-from-left-enter-active {
    -webkit-transform: scale(1);
            transform: scale(1); }

.grow-from-left-leave {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out; }
  .grow-from-left-leave.grow-from-left-leave-active {
    -webkit-transform: scale(0);
            transform: scale(0); }

.grow-from-right-enter {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  transition: -webkit-transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  transition: transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  transition: transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8); }
  .grow-from-right-enter.grow-from-right-enter-active {
    -webkit-transform: scale(1);
            transform: scale(1); }

.grow-from-right-leave {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out; }
  .grow-from-right-leave.grow-from-right-leave-active {
    -webkit-transform: scale(0);
            transform: scale(0); }

.grow-from-center-enter {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0; }
  .grow-from-center-enter.grow-from-center-enter-active {
    -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: -webkit-transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    -webkit-transform: scale(1);
            transform: scale(1); }

.grow-from-center-leave {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0; }
  .grow-from-center-leave.grow-from-center-leave-active {
    -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: -webkit-transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.2s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    -webkit-transform: scale(0);
            transform: scale(0); }

.about_div_bgimg_wrapper {
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #eee; }

.about_div_bgimg {
  height: 95vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center; }

.about_div_bgimg_cover {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center; }

progress {
  /* Turns off styling - not usually needed, but good to know. */
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /* gets rid of default border in Firefox and Opera. */
  border: none;
  /* Dimensions */
  width: 500px;
  height: 12px; }

.rm-progress {
  background: #cccccc;
  border-radius: 5px; }

/* Firefox */
progress.rm-progress::-moz-progress-bar {
  border-radius: 5px;
  background-image: -moz-linear-gradient(center bottom, #dc3c3c 37%, #ef7574 69%); }

/* Chrome */
progress.example3::-webkit-progress-value {
  border-radius: 5px;
  background-image: -webkit-linear-gradient(center bottom, #dc3c3c 37%, #ef7574 69%); }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input; }

